import { PackageSubheading, WatchPartyText } from './elements'
import React from 'react'

export function SponsorPackageAuText() {
  return (
    <>
      <PackageSubheading>Package includes:</PackageSubheading>
      <ul>
        <li>
          <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
            Karma: A Yogi’s Guide to Crafting Your Destiny
          </span>{' '}
          - Signed by Sadhguru
        </li>
        <li>Access to 3 events</li>
        <li>
          <strong>Karma</strong> T-shirt
        </li>
        <li>
          72 hours access to the event recording from the start time of each
          event
        </li>
        <WatchPartyText />
      </ul>

      <PackageSubheading>
        Fee: <span style={{ fontWeight: 'normal' }}>$550</span>
      </PackageSubheading>
    </>
  )
}
