import React from 'react'
import PackageRegPage from './_package.js'
import { SponsorPackageAuText } from '../../components/page/package/elementsAu'

export default function SponsorPackageAuPage() {
  return (
    <PackageRegPage
      heading={'Sponsor Package'}
      jotformIds={'211266914724153'}
      text={<SponsorPackageAuText />}
    />
  )
}
